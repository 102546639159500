import "./App.css";
import logo from "./ai.jpeg";

function App() {
  return (
    <div className="App">
      <img src={logo} alt="AI" className="logo" />
      <div className="coming-soon">coming soon</div>
      <a href="mailto:info@ourteam.ai" className="contact-button">
        contact: info@ourteam.ai
      </a>
      <p className="footer">
        copyright © 2024 ourteam <br /> all rights reserved.
      </p>
    </div>
  );
}

export default App;
